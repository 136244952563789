.importe-descuento {
  color: #acaecb;
  text-decoration: line-through;
}

.ant-table-color {
  color: #595c97;
  background-color: #e7eef7;
}

.Invoice-table .ant-table-container {
  overflow: auto;
}
